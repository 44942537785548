<template>
  <transition name="slide">
    <CCard>
      <CCardBody>
        <CButton color="primary" @click="add()"
          ><CIcon name="cil-plus" /> Add experience status</CButton
        >
        <br />
        <br />
        <CAlert :show.sync="dismissCountDown" :color="alertType" fade>
          {{ message }}</CAlert
        >

        <CDataTable
          :items="items"
          :fields="fields"
          table-filter
          items-per-page-select
          :items-per-page="15"
          hover
          pagination
        >
          <template #name="{ item }">
            <td>
              <strong>{{ item.name }}</strong>
            </td>
          </template>
          <template #edit="{ item }">
            <td>
              <CButton color="primary" @click="edit(item.id)"
                ><CIcon name="cil-pencil" /> Edit</CButton
              >
            </td>
          </template>
          <template #delete="{ item }">
            <td>
              <CButton
                color="danger"
                @click="
                  warningModal = true;
                  deleteId = item.id;
                  deletedItem = item;
                "
                ><CIcon name="cil-trash" /> Delete</CButton
              >
            </td>
          </template>
        </CDataTable>
        <CModal
          v-model="deletedItem.id"
          title="Are you sure to delete experience status?"
          color="danger"
          :show.sync="warningModal"
          @update:show="closeModal"
        >
          <CRow>
            <CCol col="6">
              <CInput label="Name" v-model="deletedItem.name" disabled />
            </CCol>
          </CRow>
        </CModal>
      </CCardBody>
    </CCard>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "ExperienceStatuses",
  data: () => {
    return {
      items: [],
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      alertType: "danger",
      deletedItem: [],
      warningModal: false,
      fields: ["name", "edit", "delete"],
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      message: "",
      showMessage: false,
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    };
  },
  computed: {},
  methods: {
    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.deleteId);
      }
    },
    getRowCount(items) {
      return items.length;
    },
    link(id) {
      return `experiencestatuses/${id.toString()}`;
    },
    editLink(id) {
      return `experiencestatuses/create/${id.toString()}`;
    },
    edit(id) {
      const editLink = this.editLink(id);
      this.$router.push({ path: editLink });
    },
    delete(id) {
      let self = this;
      axios
        .delete(this.$apiAdress + "/v1/ExperienceStatus/" + id)

        .then(function () {
          self.message = "Successfully deleted experience status.";
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.showAlert();
          self.getData();
        })
        .catch(function (error) {
          self.message = error;
          self.showAlert();
        });
    },
    add() {
      this.$router.push({
        path: "experiencestatuses/create/" + this.emptyGuid,
      });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    getData() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ExperienceStatus")
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
          self.message = error;
          self.showAlert();
        });
    },
  },
  mounted: function () {
    this.getData();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
